import { Col, Container, Row } from "react-bootstrap";
import HomeHeader from "./home-header/HomeHeader";
import HomeTile from "./home-tile/HomeTile";
import { FileText, Gitlab, BookHalf } from 'react-bootstrap-icons';

function Home() {
  return (
    <div>
      <HomeHeader 
        title="Welcome to DevyonQ Admin Panel!" 
        lead="Transform Your Development Workflow with AI-Powered Guidance"
        info="At DevyonQ, we streamline your coding journey by integrating seamlessly with your projects. Our platform is designed to enhance productivity by providing easy access to system instructions, managing your Git repositories, and organizing your projects effectively."      
      />
      <Container className="mt-5">
        <Row>
          <Col className="col-12 col-md-4 col-lg-4">
            <HomeTile 
              title={<><FileText size={24} /> System Instructions</>} 
              actionLink="/systemInstructions"
            >
              System Instructions are your go-to resource for standardized guidance and best practices. These instructions can be applied across various projects and repositories, ensuring consistency and efficiency in your development processes. Whether you need to set up project templates or define common coding standards, our AI-driven instructions are tailored to support your needs.
            </HomeTile>
          </Col>
          <Col className="col-12 col-md-4 col-lg-4">
            <HomeTile 
              title={<><Gitlab size={24} /> Repositories</>} 
              actionLink="/repositories"
            >
              Managing your codebase is crucial, and our platform simplifies this with comprehensive Code repository management. Connect, view, and organize your repositories effortlessly. With DevyonQ, you get a unified view of your codebase, making version control and collaboration more intuitive.
            </HomeTile>
          </Col>
          <Col className="col-12 col-md-4 col-lg-4">
            <HomeTile 
              title={<><BookHalf size={24} /> Knowledge Base</>} 
              actionLink="/knowledgeBase"
            >
                  The Knowledge Base is your centralized hub for key information, best practices, and technical documentation. Organize and manage your project's vital knowledge entries with ease. Add URLs, tag them for easy filtering, and ensure that crucial information is always at your team's fingertips. Whether it's technical guides, project resources, or key references, the Knowledge Base helps you keep everything in one place.
            </HomeTile>
          </Col>
        </Row>
      </Container>  
    </div>
  )
}

export default Home;
