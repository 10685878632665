import React, { useState, useEffect } from "react";
import { Button, Container, Badge } from "react-bootstrap";
import { Tags, BookHalf } from 'react-bootstrap-icons';

import ListView from '../../components/list-view/ListView'; 
import UrlAndTagsInputModal from '../../components/url-tags-input-modal/UrlAndTagsInputModal'; 
import EmptyListMessage from '../../components/empty-list-message/EmptyListMessage';
import MessageBox from '../../components/message-box/MessageBox';
import RelativeDateWithTooltip from '../../components/relative-date-with-tooltip/RelativeDateWithTooltip';
import LocalLoadingSpinner from '../../components/local-spinner/LocalSpinner'; 

import useApiGet from "../../hooks/useApiGet";
import useApiPostJson from "../../hooks/useApiPostJson";
import useApiDelete from "../../hooks/useApiDelete";

import './KnowledgeBase.scss';

function KnowledgeBase() {
  const [entries, setEntries] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showMessageBox, setShowMessageBox] = useState(false);
  const [targetId, setTargetId] = useState(null); 
  const [pendingDeleteId, setPendingDeleteId] = useState(null);
  const [loading, setLoading] = useState(false);

  const [apiData, getKnowledge] = useApiGet("api/knowledge", true);
  const [postResponse, postEntry, clearPostResponse] = useApiPostJson("api/knowledge/website");
  const [deleteResponse, requestDelete] = useApiDelete("api/knowledge/{id}");

  useEffect(() => {
    setLoading(true);
    if (apiData) {
      const formattedData = apiData.map(item => ({
        id: item.id,
        url: item.websiteUrl,
        tags: item.tags.map(tag => tag.tag).join(', '),
        lastModified: item.lastModified,
        title: item.websiteUrl
      }));
      setEntries(formattedData);
    }
    setLoading(false);
  }, [apiData]);


  useEffect(() => {  
    if (postResponse) {
      clearPostResponse();
      getKnowledge();
    }
  }, [postResponse, clearPostResponse, getKnowledge]);

  useEffect(() => {
    if (deleteResponse && deleteResponse.status === 200 && pendingDeleteId !== null) {
      setEntries((prevEntries) =>
        prevEntries.filter(entry => entry.id !== pendingDeleteId)
      );
      setShowMessageBox(false);
      setPendingDeleteId(null);
    }
  }, [deleteResponse, pendingDeleteId]);

  const handleAddEntry = () => {
    setShowModal(true);
  };

  const confirmDelete = () => {
    setPendingDeleteId(targetId);
    requestDelete({ params: { id: targetId } });
    setShowMessageBox(false);
  };

  const handleDeleteEntry = (id) => {
    setTargetId(id);
    setShowMessageBox(true);
  };

  const handleSaveEntry = (entry) => {
    const entryRequest = {
      url: entry.url,
      tags: entry.tags
    };
    postEntry({
      body: entryRequest
    });
    
    postResponse && getKnowledge();
    
    setShowModal(false);
  };

  const renderMetadata = (item) => {
    return (
      <RelativeDateWithTooltip 
        date={item.lastModified} 
        label="Modified: " 
      />
    );
  };

  const renderTags = (item) => {
    return item.tags.split(',').map((tag, index) => (
      <Badge key={index} pill bg="info" className="me-1">
        {tag}
      </Badge>
    ));
  };  

  return (
    <Container className="knowledge-base-container">
      <div>
        {loading ? (
          <LocalLoadingSpinner isLoading={loading} />
        ) : entries.length === 0 ? (
          <EmptyListMessage
            onCreate={handleAddEntry}
            title="Knowledge Base"
            icon={BookHalf}
            message="This is the knowledge base. There are no entries yet. Use the button below to create your first entry."
            buttonText="START"
          />
        ) : (
          <>
            <div className="common-header">
              <div className="header-inner">
                <h1>Knowledge Base</h1>
                <div className="filter-and-create">
                  <Button className="create-button" onClick={handleAddEntry}>
                    Create
                  </Button>
                </div>
              </div>
            </div>
            <ListView
              items={entries}
              onDelete={handleDeleteEntry}
              renderIcon={() => <Tags />}
              renderMetadata={renderMetadata}
              renderTags={renderTags}
              showEdit={() => false}
              showDelete={() => true}
            />
          </>
        )}
  
        <UrlAndTagsInputModal
          show={showModal}
          onHide={() => setShowModal(false)}
          onSubmit={handleSaveEntry}
        />
  
        <MessageBox
          show={showMessageBox}
          onHide={() => setShowMessageBox(false)}
          onConfirm={confirmDelete}
          title="Delete entry"
          message="Are you sure you want to delete this entry?"
          confirmText="Delete"
          cancelText="Cancel"
          isDelete={true}
        />
      </div>
    </Container>
  );  
}

export default KnowledgeBase;
