import './HomeHeader.scss'

function HomeHeader(props) {
  return (
      <div className="home-header d-flex justify-content-center p-3">
        <div className="content">
            <h1 className="title">{props.title}</h1>
            <p className="lead">
              {props.lead}
            </p>
            <hr/>
            <p className="info">
              {props.info}
            </p>
        </div>
      </div>
  );
}

export default HomeHeader;
