import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { useAuth } from "oidc-react";

import './HomeTile.scss'

function HomeTile(props) {
  const auth = useAuth(); 
  const isAuthenticated = auth && auth.userData;

  return (
    isAuthenticated ? (
      <div className="home-tile d-flex flex-column justify-content-between p-3">
        <div className="content flex-grow-1">
          <h2 className="title">{props.title}</h2> 
          <p className="info">
            {props.children} 
          </p>
        </div>
        <div className="button-group">
          <Link to={props.actionLink}>
            <Button className="button-primary">Try it out</Button>
          </Link>
        </div>
      </div>
    ) : null
  );
}

export default HomeTile;
