function formatString (input, params){
    if(params == null)
      return input;
    let result = input;
    for (const [key, value] of Object.entries(params)) {
      result = result.replace(`{${key}}`, value)
    }
    return result;
  }

  export default formatString;