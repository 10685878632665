import { useEffect } from "react";
import { useState } from "react";

function useAppSettingsFetch() {

    const [settings, setSettings] = useState(null);

    useEffect(() => {
        fetch('/api/settings')
        .then(resp=>resp.json())
        .then(res=>setSettings(res));
    }, [])

    return settings;
}

export default useAppSettingsFetch;