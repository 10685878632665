import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { formatDistanceToNow, format } from 'date-fns';

function RelativeDateWithTooltip({ date, label = '' }) {
  const concreteDate = format(new Date(date), 'PPpp');
  const relativeDate = formatDistanceToNow(new Date(date), { addSuffix: true });

  
  const handleClick = (e) => {
    e.stopPropagation();
  };

  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id={`tooltip-${date}`}
      onClick={handleClick}>{concreteDate}</Tooltip>}
    >
      <span onClick={handleClick}>{label} {relativeDate}</span>
    </OverlayTrigger>
  );
}

export default RelativeDateWithTooltip;
