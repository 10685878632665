import React, { useState } from 'react';
import { Modal, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Copy } from 'react-bootstrap-icons';
import './GeneratedTokenModal.scss';

function GeneratedTokenModal({ show, onHide, token }) {
  const [copySuccess, setCopySuccess] = useState(false);

  const handleCopy = () => {
    if (token) {
      navigator.clipboard.writeText(token);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    }
  };

  return (
    <Modal show={show} onHide={onHide} className="token-modal">
      <Modal.Header closeButton>
        <Modal.Title>API Token Created Successfully</Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <p className="mb-2">Here is your newly generated API token:</p>
          <div className="token-display d-flex flex-row gap-4 align-items-center">
          <div className="token-text">
            {token}
          </div>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip-top">{copySuccess ? 'Token copied to clipboard!' : 'Copy API token to clipboard'}</Tooltip>}
            >
              <div>
                <Copy className="copy-icon" onClick={handleCopy} />
              </div>        
            </OverlayTrigger>
          </div>
        <p className="mt-2 text-primary">
          <b>Important: </b> This token will only be displayed once. Please copy it now and store it securely. You won't be able to retrieve it later.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default GeneratedTokenModal;
