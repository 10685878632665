import { Outlet } from "react-router-dom";
import TopBar from "./top-bar/TopBar";
import Breadcrumbs from "./breadcrumbs/Breadcrumbs";

function RootLayout() {
  return (
      <div>
        <TopBar />
        <Breadcrumbs></Breadcrumbs>
        <Outlet />
      </div>
  );
}
 

export default RootLayout;
