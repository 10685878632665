import useApiFetch from "./useApiFetch";
import { useEffect, useState } from "react";

function useApiPostJson(url) {
  const [response, invoke] = useApiFetch(url, "POST", false);
  const [postResponse, setPostResponse] = useState(null);

  const post = (request) => {
    if (request !== null) {
      invoke(
        { body: JSON.stringify(request.body) },
        {
          Accept: "application/json",
          "Content-Type": "application/json",
        }
      );
    }
  };

  useEffect(() => {
    if (response) {
      response.json().then((res) => {
        setPostResponse(res);
      });
    }
  }, [response]);

  const clearPostResponse = () => {
    setPostResponse(null);
  };

  return [postResponse, post, clearPostResponse];
}

export default useApiPostJson;

