import { useEffect, useState } from "react";
import useApiFetch from "./useApiFetch";

function useApiFileUpload(url) {
  const [uploadResponse, setUploadResponse] = useState(null);
  const [response, invoke] = useApiFetch(url, "POST", false);

  const uploadFile = (file, headers) => {
    const formData = new FormData();
    formData.append("file", file);
    invoke({ body: formData }, headers);
  };

  useEffect(() => {
    if (response) {
      setUploadResponse(response);
    }
  }, [response]);

  const resetUploadResponse = () => {
    setUploadResponse(null);
  };

  return [uploadResponse, uploadFile, resetUploadResponse];
}

export default useApiFileUpload;
