import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'oidc-react';

const RedirectHandler = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    const redirectAfterLogin = localStorage.getItem('redirectAfterLogin');
    if (auth.userData) {
      if (redirectAfterLogin) {
        localStorage.removeItem('redirectAfterLogin');
        navigate(redirectAfterLogin);
      } else {
        navigate('/');
      }
    }
  }, [auth.userData, navigate]);

  return null; 
};

export default RedirectHandler;
