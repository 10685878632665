import { useState } from "react";
import { Container, Nav, Navbar, Button, NavDropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { Person } from "react-bootstrap-icons";
import { useAuth } from "oidc-react";
import './TopBar.scss';
const logo = require("../../../images/logo.png");

function TopBar() {
  const [expanded, setExpanded] = useState(false);
  const auth = useAuth(); 
  const isAuthenticated = auth && auth.userData;
  const handleLogin = () => {
    auth.signIn();
  };

  const handleLogout = () => {
    auth.signOutRedirect();
  };  
  return (
    <Navbar
      bg="dark"
      variant="dark"
      expand="lg"
      sticky="top"
      expanded={expanded}
    >
      <Container fluid>
        <Navbar.Brand as={NavLink} to="/">
          <img
            alt=""
            src={logo}
            width="45"
            height="45"
            className="d-inline-block align-center"
          />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={(_) => setExpanded(expanded ? false : true)}
        />
        <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
            <Nav.Link as={NavLink} to="/" onClick={() => setExpanded(false)}>
              Home
            </Nav.Link>
            {isAuthenticated && (
              <>
                <Nav.Link
                  as={NavLink}
                  to="/systemInstructions"
                  onClick={() => setExpanded(false)}
                >
                  System Instructions
                </Nav.Link>
                <Nav.Link
                  as={NavLink}
                  to="/repositories"
                  onClick={() => setExpanded(false)}
                >
                  Repositories
                </Nav.Link>
                <Nav.Link
                  as={NavLink}
                  to="/knowledgeBase"
                  onClick={() => setExpanded(false)}
                >
                  Knowledge Base
                </Nav.Link>
              </>
            )}
          </Nav>
          <Nav className="ms-auto">
            {isAuthenticated ? (
              <NavDropdown
                title={<Person size={30} />}
                id="user-dropdown"
                className="no-arrow-dropdown"
                align="end"
              >
                <NavDropdown.Item onClick={(e) => e.preventDefault()} className="person-info-dropdown-item">{auth.userData.profile.name}</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/apiTokens">API Tokens</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={handleLogout}>
                  Log Out
                </NavDropdown.Item>
              </NavDropdown>
            ) : (
              <Button variant="outline-light" onClick={handleLogin}>
                Log In
              </Button>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default TopBar;