import React, { useState, useEffect } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import "./InputModal.scss";

function InputModal({ 
  show, 
  onHide, 
  onSubmit, 
  title = "Input", 
  label = "Enter value", 
  placeholder = "Type something...", 
  initialValue = "",
  validation = (value) => value !== "",
  validationMessage = "This field is required.",
  optionalFields = [],
  rowsNumber = 1,
  typeOfInput = "text"
}) {
  const [inputValue, setInputValue] = useState(initialValue);
  const [isValid, setIsValid] = useState(true);
  const [optionalValues, setOptionalValues] = useState([]);
  const [hasEdited, setHasEdited] = useState(false);

  useEffect(() => {
    if (show) {
      if (!hasEdited && initialValue) {
        setInputValue(initialValue);
      }

      if (optionalFields.length > 0) {
        setOptionalValues(
          optionalFields.map(field => ({
            value: field.initialValue || "",
            isValid: true,
          }))
        );
      }
    } else {
      setHasEdited(false);
      setInputValue("");
      if (optionalFields.length > 0) {
        setOptionalValues(optionalFields.map(() => ({ value: "", isValid: true })));
      }
    }
  }, [show, initialValue, optionalFields, hasEdited]);

  const handleSave = () => {
    const isMainInputValid = validation(inputValue);
    const allOptionalValid = optionalValues.every(opt => opt.isValid);

    if (isMainInputValid && allOptionalValid) {
      const finalOptionalValues = optionalValues.map(opt => opt.value);
      onSubmit(inputValue, finalOptionalValues);
      setHasEdited(false);
    } else {
      setIsValid(isMainInputValid);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setIsValid(validation(value));
    setHasEdited(true);
  };

  const handleOptionalChange = (index, value) => {
    const updatedOptionalValues = [...optionalValues];
    const field = optionalFields[index];
    
    let isValid = true;
    
    if (field.validation && value.trim() !== "") {
      isValid = field.validation(value);
    }

    updatedOptionalValues[index] = { value, isValid };
    setOptionalValues(updatedOptionalValues);
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>{label}</Form.Label>
          <Form.Control 
            as={typeOfInput === "textarea" ? "textarea" : "input"}           
            rows={rowsNumber} 
            placeholder={placeholder} 
            value={inputValue} 
            onChange={handleChange} 
            isInvalid={!isValid}
          />
          {!isValid && <Form.Control.Feedback type="invalid">{validationMessage}</Form.Control.Feedback>}
        </Form.Group>

        {optionalFields.map((field, index) => (
          <Form.Group key={index} className="mt-3">
            <Form.Label>{field.label}</Form.Label>
            <Form.Control 
              type="text" 
              placeholder={field.placeholder} 
              value={optionalValues[index]?.value || ""} 
              isInvalid={field.validation && !optionalValues[index]?.isValid}
              onChange={(e) => handleOptionalChange(index, e.target.value)}
            />
            {field.mutedText && (
              <Form.Text className="text-muted">
                {field.mutedText}
              </Form.Text>
            )}
            {field.validation && !optionalValues[index]?.isValid && (
              <Form.Control.Feedback type="invalid">{field.validationMessage}</Form.Control.Feedback>
            )}
          </Form.Group>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default InputModal;
