import React from "react";
import { Button, Modal } from "react-bootstrap";
import formatString from "../../js-helpers/StringFormat";

function MessageBox(props) {
  const handleHide = () => {
    if (props.onHide) props.onHide();
  };

  const handleConfirm = () => {
    if (props.onHide) props.onHide();
    if (props.onConfirm) props.onConfirm(props.params);
  };

  const { cancelText = "Cancel", confirmText = "OK", isDelete = false } = props;

  return (
    <>
      <Modal show={props.show} onHide={handleHide}>
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{formatString(props.message, props.params)}</Modal.Body>
        <Modal.Footer>
          <Button
            className={isDelete ? "btn-danger" : "button-secondary"}
            onClick={handleConfirm}
          >
            {confirmText}
          </Button>
          <Button variant="secondary" onClick={handleHide}>
            {cancelText}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default MessageBox;
