import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useGlobalLoading } from '../../context-providers/global-loading-context/GlobalLoadingContext'
import './GlobalLoadingSpinner.scss';

const GlobalLoadingSpinner = () => {
  const { loading } = useGlobalLoading();

  if (!loading) return null;

  return (
    <div className="global-loading-spinner">
      <Spinner animation="border" variant="primary" />
    </div>
  );
};

export default GlobalLoadingSpinner;
