import React, { useRef } from "react";
import { Button, Container } from "react-bootstrap";
import { Upload } from 'react-bootstrap-icons'; 
import './EmptyListMessage.scss';

function EmptyListMessage({ 
  onCreate, 
  title, 
  icon: Icon, 
  message, 
  buttonText, 
  importEnabled = false, 
  onImport 
}) {
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && onImport) {
      onImport(file);
    }
  };

  return (
    <Container>
      <div className="empty-list-message">
        <h1 className="empty-list-title">
          {Icon && <Icon size={50} className="icon-title" />} {title}
        </h1>
        <p className="empty-list-text">
          {message}
        </p>
        <div className="button-group">
          <Button onClick={onCreate} className="circular-button circle-button">
            {buttonText}
          </Button>

          {importEnabled && (
            <>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
              <Button 
                onClick={() => fileInputRef.current.click()} 
                className="circular-button circle-button"
              >
                <Upload />
              </Button>
            </>
          )}
        </div>
      </div>
    </Container>
  );
}

export default EmptyListMessage;
