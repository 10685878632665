import {
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import { AuthProvider } from "oidc-react";
import { Spinner } from "react-bootstrap";
import { useAppSettings } from "./context-providers/app-settings-provider/AppSettingsProvider";
import { IntlProvider } from "react-intl";
import { routes } from "./Routes";
import RedirectHandler from "./components/RedirectHandler";
import GlobalLoadingSpinner from './components/global-loading-spinner/GlobalLoadingSpinner';

const router = createBrowserRouter([
  ...routes,
  {
    path: "/redirect",
    element: <RedirectHandler />,
  }
]);

function App() {
  const appSettings = useAppSettings();

  if (appSettings != null) {
    return (
      <div>
        <AuthProvider
          {...appSettings.openId}
          autoSignIn={false}
          onSignIn={() => {
            window.location.href = "/redirect";
          }}
          onSignOut={() => {
            window.location = "/";
          }}
        >
          <IntlProvider locale="en">
          <GlobalLoadingSpinner />
            <RouterProvider router={router} />
          </IntlProvider>
        </AuthProvider>
      </div>
    );
  }
  return (
    <div className="full-page d-flex justify-content-center align-items-center">
      <Spinner variant="primary" size="xxl"></Spinner>
    </div>
  );
}

export default App;
