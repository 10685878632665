import { useEffect, useState } from "react";
import useApiFetch from "./useApiFetch";

function useApiGet(url, imminentInvoke = false) {
  const [data, setData] = useState(null);
  const [response, perfromRequest, inProgress] = useApiFetch(url, "GET", imminentInvoke);

  useEffect(() => {
    if (response != null) {
        if(!response.bodyUsed){
        if(response.status === 200)
          response.json().then(d=>setData(d));
        else
          setData(null);
      }
    }
  }, [response]);

  const performGet = (request) => {
    perfromRequest(request);
  };

  return [data, performGet, inProgress];
}

export default useApiGet;
