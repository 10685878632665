import useApiFetch from "./useApiFetch";

function useApiDelete(url) {
  const [response, performRequest, inProgress] = useApiFetch(url, "DELETE", false);

  const requestDelete = (request) => {
    if (request !== null) performRequest(request);
  };

  return [response, requestDelete, inProgress];
}

export default useApiDelete;
