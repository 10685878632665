import { useEffect, useState } from "react";
import useApiFetch from "./useApiFetch";

function useApiFileDownload(baseUrl) {
  const [response, invoke] = useApiFetch(baseUrl, "GET", false);
  const [filename, setFilename] = useState("download.json");

  useEffect(() => {
    if (!response) return;
    
    response.blob()
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
  }, [response, filename]);

  const download = (id, customFilename = "download.json") => {
    const finalUrl = id ? `${baseUrl}/${id}` : baseUrl;
    setFilename(customFilename);
    invoke({ url: finalUrl });
  };

  return download;
}

export default useApiFileDownload;
