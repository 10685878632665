import "./Breadcrumbs.scss";
import { Link, useLocation } from "react-router-dom";
import useReactRouterBreadcrumbs from "use-react-router-breadcrumbs";

const routes = [
  { path: '/repositories/:id', breadcrumb: DetailNameCrumb },
];

const formatBreadcrumb = (breadcrumb) => {
  if (breadcrumb.props.children != null){
    return breadcrumb.props.children.replace(/([a-z])([A-Z])/g, "$1 $2");
  }
   else 
  return breadcrumb;
};

function Breadcrumbs() {
  const crumbs = useReactRouterBreadcrumbs(routes);

  if (crumbs.length <= 1) return null;

  return (
    <div className="breadcrumb-container">
      <div className="ms-2">
        {crumbs.map(({ match, breadcrumb }, index) => {
          const isLast = index === crumbs.length - 1;

          if (isLast) {
            return (
              <span key={match.pathname} className="last-breadcrumb">
                {formatBreadcrumb(breadcrumb)}
              </span>
            );
          }

          return (
            <span key={match.pathname} className="breadcrumb-link">
              <Link
                className="link-secondary link-underline-opacity-0"
                key={match.pathname}
                to={match.pathname}
              >
                <span>{formatBreadcrumb(breadcrumb)}</span>
              </Link>
              <span className="breadcrumb-arrow">{">"}</span>
            </span>
          );
        })}
      </div>
      <hr />
    </div>
  );
}

function DetailNameCrumb() {
  const location = useLocation();
  return <span>{location.state?.crumbName}</span>;
}

export default Breadcrumbs;
