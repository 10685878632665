import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import HttpErrorHandler from "./components/http-error-handler/HttpErrorHandler";
import AppSettingsProvider from "./context-providers/app-settings-provider/AppSettingsProvider";
import { GlobalLoadingProvider } from './context-providers/global-loading-context/GlobalLoadingContext';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AppSettingsProvider>
    <HttpErrorHandler>
      <GlobalLoadingProvider>
        <App />
      </GlobalLoadingProvider>
    </HttpErrorHandler>
  </AppSettingsProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
