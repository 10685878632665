import { useEffect, useReducer, useState } from "react";
import formatString from "../js-helpers/StringFormat";
import { useHttpErrorHandler } from "../components/http-error-handler/HttpErrorHandler";
import { useAppSettings } from "../context-providers/app-settings-provider/AppSettingsProvider";

function useApiFetch(urlPath, method, imminentInvoke) {
  const [response, setResponse] = useState(null);

  const [request, setRequest] = useState(null);

  const [additonalHeaders, setAdditionalHeaders] = useState({});

  const [inProgress, setInProgress] = useState(false);

  const [forceInvoke, setForceInvoke] = useReducer(
    (v) => (v === null ? false : !v),
    null
  );

  const [setError] = useHttpErrorHandler();

  const appSettings = useAppSettings();

  useEffect(() => {
    if (appSettings == null) return;
    let url;
    if (appSettings.apiUrl) url = `${appSettings.apiUrl}/${urlPath}`;
    else {
      url = urlPath;
      if (!url.startsWith('/'))
        url = '/' + url
    }
    if (!imminentInvoke && request == null && forceInvoke == null) return;
    let formattedUrl = formatString(url, request?.params);
    let headers = undefined;

    const sessionKey = `oidc.user:${appSettings.openId.authority}:${appSettings.openId.clientId}`;
    const authDataJson = sessionStorage.getItem(sessionKey);
    if (authDataJson) {
      const authData = JSON.parse(authDataJson);
      headers = {
        Authorization: `Bearer ${authData.access_token}`,
      };
    }

    setInProgress(true);

    fetch(formattedUrl, {
      method: method,
      body: request?.body,
      headers: { ...headers, ...additonalHeaders },
    })
      .then((response) => {
        if (response.ok) setResponse(response);
        else {
          if (response.status === 400) response.json().then((v) => setError(v));
          else if (response.status === 401)
            setError({
              errorCode: "AI-401",
              errorMessage: "This action requires you to authenticate.",
            });
          else if (response.status === 403)
            setError({
              errorCode: "AI-403",
              errorMessage: "You are not authorized to perform this action.",
            });
          else
            setError({
              errorCode: "AI-999",
              errorMessage:
                "An unexpected error occured while contacting the server.",
            });
        }
        setInProgress(false);
      })
      .catch((error) => {
        setInProgress(false);
        setError({ errorCode: "AI-999", errorMessage: error.message });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlPath, request, method, forceInvoke, imminentInvoke, appSettings]);

  const performRequest = (request, headers) => {
    if (headers)
      setAdditionalHeaders(headers);
    if (request !== undefined) setRequest(request);
    else setForceInvoke();
  };

  return [response, performRequest, inProgress];
}

export default useApiFetch;
