import React from 'react';
import { Spinner } from 'react-bootstrap';
import './LocalSpinner.scss';

const LocalSpinner = ({ isLoading, message = "Loading..." }) => {
  if (!isLoading) return null;

  return (
    <div className="local-spinner-wrapper">
      <Spinner animation="border" role="status">
      </Spinner>
      {message && <div className="local-spinner-message">{message}</div>}
    </div>
  );
};

export default LocalSpinner;
