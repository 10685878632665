import useApiFetch from "./useApiFetch";

function useApiPatch(url) {
  const [response, invoke, inProgress] = useApiFetch(url, "PATCH", false);

  const patch = (request) => {
    if (request !== null)
      invoke(
        {
          params: request.params,
          body: JSON.stringify(request.body),
        },
        {
          Accept: "application/json",
          "Content-Type": "application/json",
        }
      );
  };

  return [response, patch, inProgress];
}

export default useApiPatch;
