import React, { createContext, useContext, useEffect, useState } from "react";
import { Toast, ToastContainer } from "react-bootstrap";

const ErrorContext = createContext(null);

function HttpErrorHandler({ children }) {
  
   const [error, setError] = useState(null);
    
   const [showError, setShowError] = useState(false);

   const [errorCode, setErrorCode] = useState(null);
   const [errorMessage, setErrorMessage] = useState(null);
  
   const [timeoutId, setTimeoutId] = useState(null);

   useEffect(()=>{
    if(error){
        if(error.errorCode && error.errorMessage){
            setErrorCode(error.errorCode);
            setErrorMessage(error.errorMessage);
        }
        setShowError(true);
        const id = setTimeout(() => {
            setShowError(false)
        }, 5000);
        setTimeoutId(id);
    }
   },[error])

  return (
    <ErrorContext.Provider value={[setError]}>
      <>{React.Children.only(children)}</>
      <ToastContainer position="top-end" className="m-3" >
      <Toast  className="position-top-end" bg="danger" show={showError} onClose={()=>{
        if(timeoutId != null){
          clearTimeout(timeoutId);
          setTimeoutId(null);
        }
        setShowError(false);
      }}>
      <Toast.Header>
        <strong className="me-auto">Server Error</strong>
        <small>{errorCode}</small>
      </Toast.Header>
      <Toast.Body style={{color:'white'}}>{errorMessage}</Toast.Body>
    </Toast>
    </ToastContainer>
    </ErrorContext.Provider>
  );
}

export function useHttpErrorHandler(){
    return useContext(ErrorContext);
}

export default HttpErrorHandler;
