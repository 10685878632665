import RootLayout from "./layouts/root-layout/RootLayout";
import Home from "./pages/home/Home";
import SystemInstructions from "./pages/system-instructions/SystemInstructions";
import CodeRepositories from "./pages/code-repositories/CodeRepositories";
import CodeRepositoryDetails from "./pages/code-repositories/CodeRepositoryDetails";
import ApiTokens from "./pages/api-tokens/ApiTokens";
import ProtectedRoute from "./components/protected-route/ProtectedRoute";
import KnowledgeBase from "./pages/knowledge-base/KnowledgeBase";
export const routes = [
  {
    path: "/",
    element: <RootLayout />,
    children: [
      {
        path: "",
        children: [
          {
            index: true,
            element: <Home />,
          },
          {
            path: "systemInstructions",
            element: <ProtectedRoute element={<SystemInstructions />} />,
          },
          {
            path: "repositories",
            element: <ProtectedRoute element={<CodeRepositories />} />,
          },
          {
            path: "repositories/:id",
            element: <ProtectedRoute element={<CodeRepositoryDetails />} />,
          },
          {
            path: "apiTokens",
            element: <ProtectedRoute element={<ApiTokens />} />,
          },
          {
            path: "knowledgeBase",
            element: <ProtectedRoute element={<KnowledgeBase />} />,
          },
        ],
      },
    ],
  },
];