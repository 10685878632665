import React, { useState, useEffect } from 'react';
import { Container, Button } from "react-bootstrap";
import { CalendarCheck, CalendarX } from 'react-bootstrap-icons';

import ListView from '../../components/list-view/ListView'; 
import InputModal from '../../components/input-modal/InputModal'; 
import GeneratedTokenModal from '../../components/generated-token-modal/GeneratedTokenModal'; 
import MessageBox from '../../components/message-box/MessageBox'; 
import RelativeDateWithTooltip from '../../components/relative-date-with-tooltip/RelativeDateWithTooltip';
import LocalLoadingSpinner from '../../components/local-spinner/LocalSpinner'; 

import useApiGet from "../../hooks/useApiGet";
import useApiPostJson from '../../hooks/useApiPostJson';
import useApiDelete from '../../hooks/useApiDelete';

import './ApiTokens.scss';

function ApiTokens() {
  const [tokens, setTokens] = useState([]);
  const [showTokenModal, setShowTokenModal] = useState(false);
  const [showGeneratedTokenModal, setShowGeneratedTokenModal] = useState(false);
  const [newToken, setNewToken] = useState("");
  const [showMessageBox, setShowMessageBox] = useState(false);
  const [targetId, setTargetId] = useState(null);
  const [pendingDeleteId, setPendingDeleteId] = useState(null);
  const [loading, setLoading] = useState(false);

  const [postResponse, createToken, clearPostResponse] = useApiPostJson("api/tokens");
  const [deleteResponse, deleteToken] = useApiDelete("api/tokens/{id}");
  const [apiData, getTokens] = useApiGet("api/tokens", true);
  
  useEffect(() => {
    setLoading(true);
    if (apiData) {
      const formattedData = apiData.map(item => ({
        id: item.id,
        text: item.description,
        createdAt: item.created,
        expiresAt: item.expiration,
        isValid: item.isValid
      }));
      setTokens(formattedData);
    }
    setLoading(false);
  }, [apiData]);

  const handleGenerateToken = async (tokenDescription) => {
    const body = { tokenDescription };
    createToken({ body });
  };

  useEffect(() => {
    if (postResponse) {
      setNewToken(postResponse);
      setShowTokenModal(false);
      setShowGeneratedTokenModal(true);
      clearPostResponse();
    }
  }, [postResponse, clearPostResponse]);

  useEffect(() => {
    if (deleteResponse && deleteResponse.status === 200 && pendingDeleteId !== null) {
      setTokens((prevTokens) => prevTokens.filter(token => token.id !== pendingDeleteId));
      setPendingDeleteId(null);
    }
  }, [deleteResponse, pendingDeleteId]);

  const handleCloseGeneratedTokenModal = () => {
    setShowGeneratedTokenModal(false);
    setNewToken("");
    getTokens();
  };

  const handleDeleteToken = (id) => {
    setTargetId(id);
    setShowMessageBox(true);
  };

  const confirmDelete = () => {
    setPendingDeleteId(targetId);
    deleteToken({ params: { id: targetId } });
    setShowMessageBox(false);
  };

  const renderIcon = (item) => {
    return item.isValid ? <CalendarCheck /> : <CalendarX />;
  };

  const renderMetadata = (item) => {
    return (
      <div className="metadata-column">
        <RelativeDateWithTooltip date={item.createdAt} label="Created: " />
        <RelativeDateWithTooltip date={item.expiresAt} label="Expires: " />
      </div>
    );
  };

  if (loading) {
    return <Container>
      <div>
        <LocalLoadingSpinner isLoading={loading} />
      </div>
    </Container>;
  }

  return (
    <Container>
      <div className="tokens-header">
        <div className="tokens-header-inner">
          <div className="d-flex justify-content-between align-items-center">
            <h1 className="tokens-title mb-0">Personal API Tokens</h1>
            <Button className="generate" onClick={() => setShowTokenModal(true)}>
              Generate New Token
            </Button>
          </div>
          <p className="tokens-description mt-2">
            API tokens allow you to access DevyonQ's features programmatically. 
            You can use these tokens to interact with the DevyonQ API, enabling seamless integration 
            with your projects and enhancing productivity by generating code directly from our platform.
          </p>
        </div>
      </div>
        
      <ListView
        items={tokens}
        onDelete={handleDeleteToken}
        renderIcon={renderIcon}
        renderMetadata={renderMetadata}
        showDelete={() => true}
        showEdit={() => false}
        emptyListText='No API tokens generated yet'
      />

      <InputModal
        show={showTokenModal}
        onHide={() => setShowTokenModal(false)}
        onSubmit={handleGenerateToken}
        title="Generate API Token"
        label="Enter a token description:"
        placeholder="Token description"
      />

      <GeneratedTokenModal
        show={showGeneratedTokenModal && newToken}
        onHide={handleCloseGeneratedTokenModal}
        token={newToken}
      />

      <MessageBox
        show={showMessageBox}
        onHide={() => setShowMessageBox(false)}
        onConfirm={confirmDelete}
        title="Delete Token"
        message="Are you sure you want to delete this token?"
        confirmText="Delete"
        cancelText="Cancel"
        isDelete={true}
      />
    </Container>
  );
}

export default ApiTokens;
