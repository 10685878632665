import React, { useContext } from "react";
import useAppSettingsFetch from "../../hooks/useAppSettingsFetch";
import { Children } from "react";

const AppSettingsContext = React.createContext(null);

function AppSettingsProvider({children}) {
  const appsettings = useAppSettingsFetch();
  return (
    <AppSettingsContext.Provider value={appsettings}>
      {Children.only(children)}
    </AppSettingsContext.Provider>
  );
}

export function useAppSettings() {
  return useContext(AppSettingsContext);
}

export default AppSettingsProvider;
