import React, { useState, useRef } from "react";
import { Button, Alert } from "react-bootstrap";
import { Trash, Pencil, InfoCircle, Download, Upload } from 'react-bootstrap-icons';
import "./ListView.scss";

function ListView({ 
  items,
  onItemClick = () => {}, 
  onEdit, 
  onDelete, 
  renderIcon, 
  renderMetadata, 
  showEdit = true, 
  showDelete = true,
  emptyListText = "",
  renderTags,
  importExportEnabled = false,
  onExport = () => {},
  onImport = () => {},
  onCreate = () => {},
  showButtonRow = false
}) {
  const [successMessage, setSuccessMessage] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      onImport(file);
      setSuccessMessage("File imported successfully!"); 
    }
  };

  const handleExportClick = () => {
    onExport();
    setSuccessMessage("File exported successfully!");
  };

  const handleClick = (id, title, e) => {
    e.stopPropagation();
    onItemClick(id, title);
  };

  const listItems = items.map((item, index) => (
    <div key={index} className="list-item" onClick={(e) => handleClick(item.id, item.title, e)}>
      <div className="icon-box">
        {renderIcon && renderIcon(item)}
      </div>
      <div className="text-and-title">
      {item.title && (
        <h3>{item.title}</h3>
      )}
        <p className="list-item-text">{item.text}</p>
        {renderTags && (
          <div className="tags">
            {renderTags(item)}
          </div>
        )}
      </div>
      <div className="metadata-and-actions">
        {renderMetadata && (
          <span className="metadata">
            {renderMetadata(item)}
          </span>
        )}
        <div className="actions">
          {showEdit(item) && (
            <Button variant="link" onClick={(e) => { e.stopPropagation(); onEdit(item.id); }}>
              <Pencil />
            </Button>
          )}
          {showDelete(item) && (
            <Button variant="link" onClick={(e) => { e.stopPropagation(); onDelete(item.id); }}>
              <Trash />
            </Button>
          )}
        </div>
      </div>
    </div>
  ));

  return (
    <div className="list-view">
      {successMessage && (
        <Alert variant="success" onClose={() => setSuccessMessage(null)} dismissible>
          {successMessage}
        </Alert>
      )}
      {showButtonRow && (
        <div className="button-row">
          <div className="left-button">
            <Button variant="primary" onClick={onCreate}>
              Create
            </Button>
          </div>
          {importExportEnabled && (
          <div className="right-buttons">
            {items.length > 0 && (
              <Button variant="secondary" onClick={handleExportClick}>
                <Download className="me-2" />
                Export
              </Button>
            )}

            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            <Button variant="secondary" onClick={() => fileInputRef.current.click()}>
              <Upload className="me-2" />
              Import
            </Button>
          </div>
          )}
        </div>
      )}
      {(!items || items.length === 0) ? (
        <div className="empty-list">
          <InfoCircle size={50} className="mb-3" />
          <p>{emptyListText}</p>
        </div>
      ) : (
        listItems
      )}
    </div>
  );
}

export default ListView;
