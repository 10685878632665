import { useEffect } from 'react';
import { useAuth } from 'oidc-react';
import { useLocation } from 'react-router-dom';

const ProtectedRoute = ({ element }) => {
  const auth = useAuth();
  const location = useLocation();
  const isAuthenticated = auth && auth.userData;

  useEffect(() => {
    if (!isAuthenticated && auth && !auth.isLoading) {
      localStorage.setItem('redirectAfterLogin', location.pathname);
      auth.signIn();
    }
  }, [isAuthenticated, auth, location.pathname]);

  return isAuthenticated ? element : null;
};

export default ProtectedRoute;
