import React, { useState, useEffect } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { TagsInput } from 'react-tag-input-component';

function UrlAndTagsInputModal({ 
  show, 
  onHide, 
  onSubmit, 
  validationMessage = "Please enter a valid URL."
}) {
  const [url, setUrl] = useState("");
  const [tags, setTags] = useState([]);
  const [isUrlValid, setIsUrlValid] = useState(true);

  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  const handleUrlChange = (e) => {
    const value = e.target.value;
    setUrl(value);
    setIsUrlValid(isValidUrl(value));
  };

  const handleSave = () => {
    if (isValidUrl(url) && tags.length > 0) {
      onSubmit({ url, tags });
      setUrl("");
      setTags([]);
      setIsUrlValid(true);
    } else {
      setIsUrlValid(isValidUrl(url));
    }
  };

  useEffect(() => {
    if (show) {
      setUrl("");
      setTags([]);
      setIsUrlValid(true);
    }
  }, [show]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Add Knowledge Base Entry</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Enter URL:</Form.Label>
          <Form.Control 
            type="text" 
            placeholder="https://example.com"
            value={url}
            onChange={handleUrlChange}
            isInvalid={!isUrlValid}
          />
          {!isUrlValid && (
            <Form.Control.Feedback type="invalid">
              {validationMessage}
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <Form.Group className="mt-3">
          <Form.Label>Tags:</Form.Label>
          <TagsInput
            value={tags}
            onChange={setTags}
            name="tags"
            placeHolder="Enter tags"
          />
          {tags.length === 0 && (
            <Form.Text className="text-muted">
              Please add at least one tag.<br />
              Press ENTER to confirm each tag.
            </Form.Text>
          )}
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button 
          variant="primary" 
          onClick={handleSave} 
          disabled={tags.length === 0 || !isUrlValid}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default UrlAndTagsInputModal;
